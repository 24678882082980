import request from '@/utils/request'


// 查询客户游戏得分记录列表
export function listGameRecord(query) {
  return request({
    url: '/customer/game-record/list',
    method: 'get',
    params: query
  })
}

export function rankingList(query) {
  return request({
    url: '/customer/game-record/rankingList',
    method: 'get',
    params: query
  })
}

export function masterList(query) {
  return request({
    url: '/customer/game-record/masterList',
    method: 'get',
    params: query
  })
}



// 查询客户游戏得分记录分页
export function pageGameRecord(data) {
  return request({
    url: '/customer/game-record/page',
    method: 'get',
    params: data
  })
}

// 查询客户游戏得分记录详细
export function getGameRecord(data) {
  return request({
    url: '/customer/game-record/detail',
    method: 'get',
    params: data
  })
}

// 新增客户游戏得分记录
export function addGameRecord(data) {
  return request({
    url: '/customer/game-record/add',
    method: 'post',
    data: data
  })
}

// 修改客户游戏得分记录
export function updateGameRecord(data) {
  return request({
    url: '/customer/game-record/edit',
    method: 'post',
    data: data
  })
}

// 删除客户游戏得分记录
export function delGameRecord(data) {
  return request({
    url: '/customer/game-record/delete',
    method: 'post',
    data: data
  })
}

import request from '@/utils/request'


// 查询设备管理信息列表
export function listEquipment(query) {
  return request({
    url: '/equipment/info/list',
    method: 'get',
    params: query
  })
}

// 查询设备管理信息分页
export function pageInfo(query) {
  return request({
    url: '/equipment/info/page',
    method: 'get',
    params: query
  })
}

// 查询设备管理信息详细
export function getInfo(data) {
  return request({
    url: '/equipment/info/detail',
    method: 'get',
    params: data
  })
}

// 新增设备管理信息
export function addInfo(data) {
  return request({
    url: '/equipment/info/add',
    method: 'post',
    data: data
  })
}

// 修改设备管理信息
export function updateInfo(data) {
  return request({
    url: '/equipment/info/edit',
    method: 'post',
    data: data
  })
}

// 删除设备管理信息
export function delInfo(data) {
  return request({
    url: '/equipment/info/delete',
    method: 'post',
    data: data
  })
}

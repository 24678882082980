import request from '@/utils/request'


// 查询游戏信息管理列表
export function listGame(query) {
  return request({
    url: '/game/info/list',
    method: 'get',
    params: query
  })
}

// 查询游戏信息管理分页
export function pageInfo(query) {
  return request({
    url: '/game/info/page',
    method: 'get',
    params: query
  })
}

// 查询游戏信息管理详细
export function getInfo(data) {
  return request({
    url: '/game/info/detail',
    method: 'get',
    params: data
  })
}

// 新增游戏信息管理
export function addInfo(data) {
  return request({
    url: '/game/info/add',
    method: 'post',
    data: data
  })
}

// 修改游戏信息管理
export function updateInfo(data) {
  return request({
    url: '/game/info/edit',
    method: 'post',
    data: data
  })
}

// 删除游戏信息管理
export function delInfo(data) {
  return request({
    url: '/game/info/delete',
    method: 'post',
    data: data
  })
}

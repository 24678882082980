<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="客户名称或编号" prop="customerName">
                <a-input v-model="queryParam.customerName" placeholder="请输入客户名称、手机号或编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="游戏名称" prop="gameId">
                <a-select v-model="queryParam.gameId" placeholder="请选择游戏">
                  <a-select-option v-for="(item,key) in gameList" :key="key" :value="item.id">{{item.gameName}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="设备名称" prop="equipmentId">
                  <a-select v-model="queryParam.equipmentId" placeholder="请选择设备">
                    <a-select-option v-for="(item,key) in equipmentList" :key="key" :value="item.id">{{item.equipmentName}}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="时间" prop="time">
                  <a-range-picker
                    v-model="time"
                    :show-time="{ format: 'HH:mm' }"
                    format="YYYY-MM-DD HH:mm"
                    :placeholder="['开始时间', '结束时间']"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="成绩排序" prop="scoreSort">
                  <a-select v-model="queryParam.scoreSort" placeholder="请选择排序方式">
                    <a-select-option :value="1">从高到低</a-select-option>
                    <a-select-option :value="2">从低到高</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="时间排序" prop="timeSort">
                  <a-select v-model="queryParam.timeSort" placeholder="请选择排序方式">
                    <a-select-option :value="1">正序</a-select-option>
                    <a-select-option :value="2">倒序</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['customer:game/record:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['customer:game/record:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['customer:game/record:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
        <a-button type="primary" @click="handleExport" v-hasPermi="['customer:gameRecord:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime,'{y}:{m}:{d} {h}:{i}') }}
        </span>
<!--        <span slot="operation" slot-scope="text, record">-->
<!--          <a-divider type="vertical" v-hasPermi="['customer:gameRecord:edit']" />-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['customer:gameRecord:edit']">-->
<!--            <a-icon type="edit" />修改-->
<!--          </a>-->
<!--          <a-divider type="vertical" v-hasPermi="['customer:gameRecord:remove']" />-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['customer:gameRecord:remove']">-->
<!--            <a-icon type="delete" />删除-->
<!--          </a>-->
<!--        </span>-->
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageGameRecord,listGameRecord, delGameRecord } from '@/api/customer/gameRecord'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {listGame} from "@/api/game/info";
import {listEquipment} from "@/api/equipment/info";
import moment from "moment";
export default {
  name: 'GameRecord',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      gameList:[],
      equipmentList:[],
      total: 0,
      time:[],
      // 查询参数
      queryParam: {
        customerName: null,
        gameId: null,
        equipmentId: null,
        scoreSort: null,
        timeSort: null,
        beginTime:null,
        endTime:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '记录编号',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '客户编号',
          dataIndex: 'customerId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '客户昵称',
          dataIndex: 'customerName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center',
          customRender(f){
            if(f==1){
              return '男'
            }else{
              return '女'
            }
          }
        },
        {
          title: '年龄',
          dataIndex: 'age',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '电话',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '游戏类型',
          dataIndex: 'gameName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备',
          dataIndex: 'equipmentName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '成绩',
          dataIndex: 'score',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    this.initGameList()
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询客户游戏得分记录列表 */
    getList () {
      this.loading = true
      if(this.time.length>0){
        this.queryParam.beginTime=moment(this.time[0]).format('YYYY-MM-DD HH:mm')
        this.queryParam.endTime=moment(this.time[1]).format('YYYY-MM-DD HH:mm')
      }
     pageGameRecord(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    initGameList(){
      listGame().then(res=>{
        this.gameList = res.data
      })
      listEquipment().then(res=>{
        this.equipmentList = res.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.time=[]
      this.queryParam = {
        customerName: undefined,
        beginTime: null,
        endTime:null,
        timeSort: null,
        scoreSort: null,
        gameId: undefined,
        equipmentId: null,
        score: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGameRecord(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('customer/game-record/export', {
            ...that.queryParam
          }, `客户游戏得分记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
